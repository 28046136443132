<script lang="ts">
import RichText from '$components/Typography/RichText.svelte'
import type { CONTACT_US_TYPE } from '$types/index'

export let data: CONTACT_US_TYPE
</script>

<section>
  <div class="content-container">
    <RichText richText={data.fields.text} />
    <div class="button-box">
      <a class="gradient" href="/contact-us" rel="external">Let's talk</a>
    </div>
  </div>
</section>

<style lang="scss">
section {
  margin: 73px 25px;

  .content-container {
    padding: 28px 25px 46px;
    background-image: url('/contact-bg.jpeg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 16px;

    :global(.rich-text) {
      font-size: 27px;
      font-weight: 300;
      letter-spacing: 0.02em;
      line-height: 31.32px;
      margin: 0 0 45px;

      @media only screen and (min-width: 1024px) {
        margin: 0 0 90px;
        font-size: 48px;
        line-height: 56.64px;
      }
    }

    @media only screen and (min-width: 1024px) {
      padding: 48px 60px 65px;
    }
  }

  .button-box {
    text-align: center;
  }

  a {
    border: 2px solid #5a586b;
    border-radius: 30px;
    background: none;
    color: white;
    min-width: 115px;
    padding: 7px 25px 9px;
    margin: 0;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.32px;
  }

  a:hover {
    color: var(--hoverOrange);
  }

  .gradient {
    background: var(--black);
    background-clip: padding-box;
    border-color: transparent;
  }

  @media only screen and (min-width: 1024px) {
    margin: 132px 100px;
  }
}

section :global(.black-button) {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
</style>
